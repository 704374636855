import axios from 'axios';
import facebookConversionsAPI from '../tools/facebook-conversions-api'; //轉換API

$(function (){
  const fb = new facebookConversionsAPI();
  const productCurrency = 'TWD';

  // 獲取用戶資料
  getUserData()
  .then(userData => {

    //在瀏覽商品時，將瀏覽商品與用戶資料一起傳送
    $(window).scroll(function() {
      if($('.viewContentTrigger').is(':visible')) {
        const viewContentProducts = [];

        $( '.indexCards .indexCard' ).each(function() {
          const productIdText = $(this).find('.productId').text().trim();
          const productId = parseFloat(productIdText.replace(/[^\d]/g, ''));

          // 商品價格只抓優惠價，如果沒有就抓售價
          if( !$(this).find('.productSalePrice').length ){
            const productPriceText = $(this).find('.productPrice').text().trim();
            const productPrice = parseFloat(productPriceText.replace(/[^\d]/g, ''));
            viewContentProducts.push({
              id: productId,
              item_price: productPrice,
            });
          } else {
            const productPriceText = $(this).find('.productSalePrice').text().trim();
            const productPrice = parseFloat(productPriceText.replace(/[^\d]/g, ''));
            viewContentProducts.push({
              id: productId,
              item_price: productPrice,
            });
          }
        });

        const viewContentProductsData = {
          "contents": viewContentProducts,
          "currency": productCurrency,
        }

        // meta pixel
          fbq('track', 'ViewContent',
              {contents: viewContentProducts, currency: productCurrency},
        {eventID: 'event.id.ViewContent.pixel'});

        // mete conversions api
        fb.sendViewContent(userData,viewContentProductsData);
        $(window).off('scroll');
      }
    });

    //加入購物車時，將該商品與用戶資料一起傳送
    $('body').on('click', '.addToCartBtn', function() {
      const addToCartProduct = [];
      const $parentLi = $(this).closest('li');
      const productId = $parentLi.find('input[name="productId"]').val().trim();
      const productPriceText = $parentLi.find('.productPrice').text().trim();
      const productPrice = parseFloat(productPriceText.replace(/[^\d]/g, ''));
      const productNumText = $parentLi.find('.spinnerBtn span:eq(1)').text().trim();
      const productNum = parseInt(productNumText.replace(/[^\d]/g, ''));

      addToCartProduct.push({
        id: productId,
        quantity: productNum,
        item_price: productPrice,
      });

      const addToCartProductData = {
        "contents": addToCartProduct,
        "currency": productCurrency,
      }

      // meta pixel
        fbq('track', 'AddToCart',
            {contents: addToCartProductData, currency: productCurrency},
            {eventID: 'event.id.AddToCart'}
        );

      // mete conversions api
      // fb.sendAddToCart(userData, addToCartProductData);
      fb.sendAddToCart(userData, addToCartProductData) // 事件發送成功後，延遲一秒跳轉至購物車
        .then(() => { //
          setTimeout(function() {
            window.location.href = 'cart-content/normal';
          }, 500);
        })
        .catch(error => {
          console.error('Error sending AddToCart event:', error);

          setTimeout(function() {
            window.location.href = 'cart-content/normal';
          }, 500);
        });

    });
  })
  .catch(error => {
      console.error('Error getting user data:', error);
  });
});

// 獲取當前使用者會員資料
function getUserData() {
  return new Promise((resolve, reject) => {

      // 如果用戶已經登錄，則發送 Axios 請求獲取用戶數據
      axios.get('/facebook-conversions-api/me')
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });

  });
}
